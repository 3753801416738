import axios from 'axios';

import store from '@/store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const { jwt } = store.state.auth;

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }

  return config;
});

api.interceptors.response.use(({ data }) => data);

export default api;
