var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(
      _vm.$route.name !== 'login' &&
      _vm.jwt &&
      _vm.$route.meta.type != 'pdf' &&
      !_vm.$vuetify.breakpoint.mobile
    )?_c('v-app-bar',{staticClass:"non-printable",attrs:{"app":"","white":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"justify":"center","cols":"12","lg":"6"}},[_c('span',{staticClass:"text-h5 font-weight-bold mr-3"},[_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"color":"inherit"},attrs:{"to":"/"}},[_c('img',{attrs:{"align":"center","src":"/logo.png","height":"50px","contain":""}}),_vm._v(" Cantonnier"),_c('span',{staticClass:"primary--text"},[_vm._v(".app")])])],1)]),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"text-right d-none d-lg-block",attrs:{"cols":"6"}},[(_vm.getAllowedRolesFromPath('/signs').includes(this.role))?_c('v-btn',{staticClass:"ml-2 mr-2",attrs:{"to":"/signs","text":""}},[_c('span',[_vm._v("Signalements")])]):_vm._e(),(
              _vm.getAllowedRolesFromPath('/interventions').includes(this.role)
            )?_c('v-btn',{staticClass:"ml-2 mr-2",attrs:{"to":"/interventions","text":""}},[_c('span',[_vm._v("Interventions")])]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Mon profil "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1753358399)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/organisation"}},[_c('v-list-item-title',[_vm._v("Mon organisation")])],1),_c('v-list-item',{attrs:{"to":"/manual"}},[_c('v-list-item-title',[_vm._v("Comment ça marche ?")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Déconnexion")])],1)],1)],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('message-bar'),_c('v-main',{class:{ 'mb-16': _vm.$vuetify.breakpoint.mobile },staticStyle:{"background-color":"#f8f8f8"}},[_c('router-view')],1),(
      _vm.$vuetify.breakpoint.mobile &&
      _vm.$route.name !== 'login' &&
      _vm.jwt &&
      _vm.$route.meta.type !== 'pdf'
    )?_c('v-bottom-navigation',{style:(_vm.isIOS ? 'height: 86px;' : ''),attrs:{"app":""},model:{value:(_vm.bottomNavigation),callback:function ($$v) {_vm.bottomNavigation=$$v},expression:"bottomNavigation"}},[(_vm.$route.name !== 'signs.new' && _vm.$route.name !== 'signs.item')?_c('v-btn',{attrs:{"fab":"","color":"primary","bottom":"","left":"","absolute":""},on:{"click":function($event){return _vm.takePicture()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-camera")])],1):_vm._e(),(_vm.getAllowedRolesFromPath('/signs').includes(this.role))?_c('v-btn',{attrs:{"to":"/signs"}},[_c('v-icon',[_vm._v("mdi-map-marker")])],1):_c('v-btn',{attrs:{"disabled":"","icon":""}}),(_vm.getAllowedRolesFromPath('/interventions').includes(this.role))?_c('v-btn',{attrs:{"to":"/interventions"}},[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1):_c('v-btn',{attrs:{"disabled":"","icon":""}}),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":"","fab":"","icon":""}}),_c('v-btn',{attrs:{"to":"/organisation"}},[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_c('v-btn',{on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-logout")])],1)],1):_vm._e(),_c('confirm-dialog',{on:{"confirm":function($event){$event ? _vm.logout(true) : null}},model:{value:(_vm.logoutDialog),callback:function ($$v) {_vm.logoutDialog=$$v},expression:"logoutDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }