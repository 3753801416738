<template>
  <v-app>
    <v-app-bar
      v-if="
        $route.name !== 'login' &&
        jwt &&
        $route.meta.type != 'pdf' &&
        !$vuetify.breakpoint.mobile
      "
      class="non-printable"
      app
      white
    >
      <v-container fluid>
        <v-row>
          <v-col
            justify="center"
            class="pb-0 pt-1"
            cols="12"
            lg="6"
          >
            <span class="text-h5 font-weight-bold mr-3">
              <router-link
                class="text-decoration-none"
                style="color: inherit"
                to="/"
              >
                <img
                  align="center"
                  src="/logo.png"
                  height="50px"
                  contain
                />
                Cantonnier<span class="primary--text">.app</span>
              </router-link>
            </span>
          </v-col>
          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            cols="6"
            class="text-right d-none d-lg-block"
          >
            <v-btn
              class="ml-2 mr-2"
              to="/signs"
              text
              v-if="getAllowedRolesFromPath('/signs').includes(this.role)"
            >
              <span>Signalements</span>
            </v-btn>
            <v-btn
              class="ml-2 mr-2"
              to="/interventions"
              text
              v-if="
                getAllowedRolesFromPath('/interventions').includes(this.role)
              "
            >
              <span>Interventions</span>
            </v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 mr-2"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  Mon profil <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item to="/organisation">
                  <v-list-item-title>Mon organisation</v-list-item-title>
                </v-list-item>
                <v-list-item to="/manual">
                  <v-list-item-title>Comment ça marche ?</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title class="error--text"
                    >Déconnexion</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <message-bar />

    <v-main
      style="background-color: #f8f8f8"
      :class="{ 'mb-16': $vuetify.breakpoint.mobile }"
    >
      <router-view />
    </v-main>

    <v-bottom-navigation
      :style="isIOS ? 'height: 86px;' : ''"
      v-model="bottomNavigation"
      app
      v-if="
        $vuetify.breakpoint.mobile &&
        $route.name !== 'login' &&
        jwt &&
        $route.meta.type !== 'pdf'
      "
    >
      <v-btn
        v-if="$route.name !== 'signs.new' && $route.name !== 'signs.item'"
        fab
        color="primary"
        bottom
        left
        absolute
        @click="takePicture()"
      >
        <v-icon color="white">mdi-camera</v-icon>
      </v-btn>

      <v-btn
        to="/signs"
        v-if="getAllowedRolesFromPath('/signs').includes(this.role)"
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn
        v-else
        disabled
        icon
      ></v-btn>
      <v-btn
        to="/interventions"
        v-if="getAllowedRolesFromPath('/interventions').includes(this.role)"
      >
        <v-icon>mdi-account-hard-hat</v-icon>
      </v-btn>
      <v-btn
        v-else
        disabled
        icon
      ></v-btn>
      <v-btn
        disabled
        fab
        icon
        class="white--text"
      >
      </v-btn>
      <v-btn to="/organisation">
        <v-icon>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn @click="logout()">
        <v-icon color="error">mdi-logout</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <confirm-dialog
      v-model="logoutDialog"
      @confirm="$event ? logout(true) : null"
    />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getAllowedRolesFromPath, getPhoto } from '@/utils';

export default {
  name: 'App',

  components: {
    ConfirmDialog: () => import('@/components/dialogs/ConfirmDialog.vue'),
    MessageBar: () => import('@/components/utils/MessageBar.vue'),
  },

  computed: {
    ...mapGetters('network', ['cachedRequestsCount']),
    ...mapState('auth', ['jwt', 'user']),
    role() {
      return this.user?.role.type;
    },
    isIOS() {
      return !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13
    },
  },

  data: () => ({
    bottomNavigation: null,
    logoutDialog: false,
  }),

  methods: {
    getAllowedRolesFromPath,

    async logout(force = false) {
      if (force !== true && this.cachedRequestsCount > 0) {
        // Display dialog to confirm logout
        this.logoutDialog = true;
        return;
      }

      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    async takePicture() {
      const image = await getPhoto();

      // Can be set to the src of an image now
      this.$router.push(`/signs/new?photo=${image.webPath}`);
    },

    // Add Hotjar Tracking Code for app.cantonnier.fr
    addHotjar() {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 3571841, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    },
  },

  created() {
    this.addHotjar();
    this.$store.dispatch('network/processCache');
  },
};
</script>

<style lang="scss">
body {
  padding-top: env(safe-area-inset-top) !important;
}

$body-font-family: 'Maven Pro', sans-serif;
$title-font: 'Maven Pro', sans-serif;

.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.v-text-field .v-input__slot {
  background-color: white;
  box-shadow: 1px 2px 3px #00000024;
}

.v-input--checkbox .v-input__slot {
  background-color: none;
}
.secondary-border {
  border: 1px solid #e9e7e8;
  border-radius: 5px;
}
.leaflet-container {
  z-index: 1;
}
.v-bottom-navigation--fixed {
  position: fixed !important;
}

.v-item-group.v-bottom-navigation .v-btn {
  height: inherit !important;
}

header.v-sheet.theme--light.v-toolbar.v-app-bar.v-app-bar--fixed {
  background-color: white;
}

.card-border {
  border: 4px solid transparent !important;
}

.card-border-selected {
  border: 4px solid #ff7c12 !important;
}

button.v-btn.v-btn--absolute.v-btn--bottom.v-btn--is-elevated.v-btn--fab.v-btn--has-bg.v-btn--left.v-btn--round.theme--light {
  position: absolute; /* or absolute */
  z-index: 1;
  border-radius: 50px;
  right: 50%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 20px !important;
  height: 80px !important;
}

.v-bottom-navigation .theme--light.v-btn.v-btn--has-bg {
  background-color: white;
}

.fill-width {
  width: 100%;
}

@for $i from 0 through 16 {
  .gap-x-#{$i},
  .gap-#{$i} {
    column-gap: $i * 4px;
  }

  .gap-y-#{$i},
  .gap-#{$i} {
    row-gap: $i * 4px;
  }
}

@media print {
  .non-printable {
    display: none !important;
  }
}
</style>
@/utils/utils
