import { Camera, CameraResultType } from '@capacitor/camera';

export function getPhoto() {
    return Camera.getPhoto({
        quality: 70,
        presentationStyle: 'popover',
        resultType: CameraResultType.Uri,
        promptLabelPhoto: 'Choisir dans la galerie',
        promptLabelPicture: 'Prendre une photo',
    });
}
