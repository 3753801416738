import Vue from 'vue';
import moment from 'moment';

import App from './App.vue';
import api from './api';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { rules } from './constants';

import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$rules = rules;
Vue.prototype.$window = window;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
