const module = {
  namespaced: true,

  state: {
    text: null,
    type: null,
    show: false,
  },

  mutations: {
    set(state, { text, type }) {
      state.text = text;
      state.type = type;
      state.show = true;
    },
  },

  actions: {
    error({ commit }, text) {
      commit('set', { text, type: 'error' });
    },

    success({ commit }, text) {
      commit('set', { text, type: 'success' });
    },

    warning({ commit }, text) {
      commit('set', { text, type: 'warning' });
    },
  },
};

export default module;
