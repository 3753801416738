import { v4 as uuid } from 'uuid';

import api from '@/api';

function toPendingSign(value, key, user) {
  const sign =
    value instanceof FormData ? JSON.parse(value.get('data')) : value;
  const images = value instanceof FormData ? value.getAll('files.images') : [];

  sign.images = images.map((image) => ({
    url: URL.createObjectURL(image),
  }));

  // Values will be set by the backend but set them anyway for display and caching purposes
  sign.id = sign.id ?? key;
  sign.author = user;
  sign.createdAt = new Date().toISOString();

  return sign;
}

const module = {
  namespaced: true,
  state: {
    selectedSigns: [],
    signs: [],
    workers: [],
  },
  getters: {
    pendingSigns(_state, _getters, rootState) {
      return Object.values(rootState.network.cache)
        .filter(
          (request) => request.url === '/signs' && request.method === 'post'
        )
        .map((request) =>
          toPendingSign(request.data, request.key, rootState.auth.user)
        );
    },
  },

  mutations: {
    // Sign CRUD
    SET_SIGNS(state, value) {
      state.signs = value;
    },
    ADD_SIGN(state, value) {
      state.signs.push(value);
    },

    // Sign selection
    ADD_SELECTED_SIGN(state, value) {
      if (!state.selectedSigns.find((sign) => sign.id === value.id)) {
        state.selectedSigns.push(value);
      }
    },

    DELETE_SELECTED_SIGN(state, value) {
      const index = state.selectedSigns.findIndex(
        (sign) => sign.id === value.id
      );
      state.selectedSigns.splice(index, 1);
    },
    RESET_SELECTED_SIGNS(state) {
      state.selectedSigns = [];
    },

    // Workers
    SET_WORKERS(state, value) {
      state.workers = value;
    },
  },
  actions: {
    // Sign CRUD
    createSign({ commit, dispatch, rootState }, value) {
      const key = uuid();

      dispatch(
        'network/fetch',
        {
          request: {
            key,
            data: value,
            method: 'post',
            url: '/signs',
          },
        },
        { root: true }
      );

      const sign = toPendingSign(value, key, rootState.auth.user);

      commit('ADD_SIGN', sign);
    },

    async getSigns({ commit, getters, state }) {
      let fetchedSigns;

      try {
        fetchedSigns = await api.get('/signs/me');
      } catch (error) {
        console.error(error);

        // No need to update the state if there are already signs and user is offline
        if (state.signs.length) return;
      }

      const signs = [...getters.pendingSigns, ...fetchedSigns];

      commit('SET_SIGNS', signs);
    },

    // Sign selection
    addSelectedSign({ commit }, value) {
      commit('ADD_SELECTED_SIGN', value);
    },

    deleteSelectedSign({ commit }, value) {
      commit('DELETE_SELECTED_SIGN', value);
    },

    resetSelectedSigns({ commit }) {
      commit('RESET_SELECTED_SIGNS');
    },

    // Workers
    setWorkers({ commit }, value) {
      commit('SET_WORKERS', value);
    },
  },
};

export default module;
