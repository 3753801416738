import { getEntities, insertEntity, removeEntity } from './db';

/**
 * An HTTP request.
 * @typedef {Object} Request
 * @property {string} key The key of the request (optional, useful for identification)
 * @property {string} method The method of the request.
 * @property {string} url The url of the request.
 * @property {FormData} data The data of the request.
 */

/**
 * A cache entity representing an HTTP request.
 * @typedef {Object} CachedRequest
 * @property {Object} metadata The metadata of the cached request.
 * @property {string} metadata.key The key of the request (optional, useful for identification)
 * @property {string} metadata.method The method of the request.
 * @property {string} metadata.url The url of the request.
 * @property {Object} data The data of the request.
 */

/**
 * Retrieve and parse the cached requests from indexed DB.
 *
 * @returns {Promise<Object<string, CachedRequest>} A promise that resolves to the cached requests indexed by their keys.
 */
export async function getCache() {
  const entities = await getEntities();

  return Object.entries(entities).reduce((cache, [key, entity]) => {
    cache[key] = entityToRequest(entity);
    return cache;
  }, {});
}

/**
 * Convert a request to an cached request.
 * In particular, convert the data from FormData to an object.
 * Conversion is needed because FormData is not supported by structured clone algorithm.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm,
 * https://github.com/whatwg/html/issues/633 and https://github.com/dexie/Dexie.js/issues/861.
 *
 * @param {Request} request The request to convert.
 *
 * @returns {CachedRequest} The converted cached request.
 */
function requestToEntity({ data, key = null, method, url }) {
  return {
    data: fromFormData(data),
    metadata: {
      key,
      method,
      url,
    },
  };
}

/**
 * Convert an cached request to a request.
 * In particular, convert the data back from an object to FormData.
 *
 * @param {CachedRequest} cachedRequest The cached request to convert.
 *
 * @returns {Request} The converted request.
 */
export function entityToRequest({ metadata, data }) {
  return {
    ...metadata,
    data: toFormData(data),
  };
}

/**
 * Insert a request to the cache.
 *
 * @param {Request} request The request to insert.
 *
 * @returns {Promise<number>} A promise that resolves to the key of the inserted request.
 */
export function insertToCache(request) {
  const entity = requestToEntity(request);
  return insertEntity(entity);
}

/**
 * Remove an cached request from the cache.
 *
 * @param {number} key The key of the cached request to remove.
 */
export function removeFromCache(key) {
  return removeEntity(key);
}

/**
 * Convert an object to FormData.
 * If a value is an array, append each item to the FormData.
 *
 * @param {Object} object The object to convert.
 *
 * @returns {FormData} The converted FormData.
 */
function toFormData(object) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(object)) {
    if (!Array.isArray(value)) {
      formData.append(key, value);
    } else {
      value.forEach((item) => formData.append(key, item));
    }
  }

  return formData;
}

/**
 * Convert FormData to an object.
 * If the formData contains multiple value for the same key, convert the value to an array.
 *
 * @param {FormData} formData The FormData to convert.
 *
 * @returns {Object} The converted object.
 */
function fromFormData(formData) {
  const object = {};
  const entries = formData.entries();

  for (const [key, value] of entries) {
    if (!Object.hasOwn(object, key)) {
      object[key] = value;
      continue;
    }

    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }

    object[key].push(value);
  }

  return object;
}
