/**
 * @description IndexedDB helper functions. Currently stores a cache of sign requests.
 */

const DB_NAME = 'cache';
const DB_VERSION = 1;
const STORE_NAME = 'signs';

/**
 * The indexed db.
 *
 * @type {IDBDatabase}
 */
let DB;

/**
 * Get the db with an object store. If they do not exist, open / create new ones.
 *
 * @returns {Promise<IDBDatabase>} A promise that resolves to the db.
 */
async function getDb() {
  return new Promise((resolve, reject) => {
    if (DB) return resolve(DB);

    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (e) => reject(e);

    request.onsuccess = () => {
      DB = request.result;
      resolve(DB);
    };

    request.onupgradeneeded = (e) => {
      const db = e.target.result;

      if (db.objectStoreNames.contains(STORE_NAME)) {
        db.deleteObjectStore(STORE_NAME);
      }

      db.createObjectStore(STORE_NAME, { autoIncrement: true });
    };
  });
}

/**
 * Get all entities from the object store.
 *
 * @returns {Promise<any[]>} A promise that resolves to the entities.
 */
export async function getEntities() {
  const db = await getDb();

  return new Promise((resolve) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');

    transaction.oncomplete = () => resolve(entities);

    const store = transaction.objectStore(STORE_NAME);
    const entities = {};
    const request = store.openCursor();

    request.onsuccess = () => {
      const cursor = request.result;

      if (!cursor) return;

      entities[cursor.key] = cursor.value;
      cursor.continue();
    };
  });
}

/**
 * Get an entity from the object store.
 *
 * @param {number} key The key of the entity to get.
 *
 * @returns {Promise<any>} A promise that resolves to the entity, or undefined if it doesn't exist.
 */
export async function getEntity(key) {
  const db = await getDb();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(key);

    request.onsuccess = () => resolve(request.result);
    request.onerror = (e) => reject(e);
  });
}

/**
 * Insert an entity to the db.
 *
 * @param {any} entity The entity to insert.
 *
 * @returns {Promise<number>} A promise that resolves to the key of the inserted entity.
 */
export async function insertEntity(entity) {
  const db = await getDb();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.add(entity);

    request.onsuccess = () => resolve(request.result);
    request.onerror = (e) => reject(e);
  });
}

/**
 * Remove an entity from the object store.
 *
 * @param {number} key The key of the entity to remove.
 */
export async function removeEntity(key) {
  const db = await getDb();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(key);

    request.onsuccess = () => resolve(request.result);
    request.onerror = (e) => reject(e);
  });
}

/**
 * Remove all entities from the object store.
 */
export async function removeEntities() {
  const db = await getDb();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.clear();

    request.onsuccess = () => resolve(request.result);
    request.onerror = (e) => reject(e);
  });
}