import api from '@/api';
import { getCache, insertToCache, removeFromCache } from '@/utils/cache';

const methodHandlers = {
  get: api.get,
  post: api.post,
  put: api.put,
  patch: api.patch,
  delete: api.delete,
};

const module = {
  namespaced: true,

  state: {
    connected: true,
    cache: await getCache(),
    retryDelay: 3000,
    retryTimeoutId: null,
  },

  getters: {
    /**
     * Get the number of cached requests.
     */
    cachedRequestsCount(state) {
      return Object.keys(state.cache).length;
    },
  },

  mutations: {
    CACHE_REQUEST(state, { request, key }) {
      state.cache[key] = request;

      // Force reactivity
      state.cache = { ...state.cache };
    },

    REMOVE_FROM_CACHE(state, key) {
      delete state.cache[key];

      // Force reactivity
      state.cache = { ...state.cache };
    },

    SET_CONNECTED(state, value) {
      state.connected = value;
    },

    SET_RETRY_TIMEOUT_ID(state, value) {
      state.retryTimeoutId = value;
    },
  },

  actions: {
    /**
     * Fetch a request.
     * If the request fails because of a network error, cache the request.
     */
    async fetch({ dispatch }, { request, cacheKey = null }) {
      try {
        await methodHandlers[request.method](request.url, request.data);
      } catch (error) {
        if (error.code === 'ERR_NETWORK') {
          if (cacheKey === null) await dispatch('cacheRequest', request);
          return;
        }

        console.error(error);
        dispatch(
          'message/error',
          "Une erreur s'est produite lors de l'enregistrement",
          { root: true }
        );
      }

      if (cacheKey !== null) {
        await dispatch('removeFromCache', cacheKey);
      }
    },

    /**
     * Cache a request and set a timeout to process the cache.
     */
    async cacheRequest({ commit, dispatch, state }, request) {
      let cacheKey;

      try {
        cacheKey = await insertToCache(request);
      } catch (error) {
        console.error(error);
        return;
      }

      commit('CACHE_REQUEST', { request, key: cacheKey });

      if (state.retryTimeoutId !== null) return;

      const retryTimeoutId = setTimeout(() => {
        dispatch('processCache');
      }, state.retryDelay);
      commit('SET_RETRY_TIMEOUT_ID', retryTimeoutId);
    },

    /**
     * Process the cache entries.
     */
    async processCache({ dispatch, commit, state }) {
      commit('SET_RETRY_TIMEOUT_ID', null);

      if (!state.connected) return;

      for (const [cacheKey, request] of Object.entries(state.cache)) {
        await dispatch('fetch', { cacheKey, request });
      }

      if (
        state.retryTimeoutId !== null ||
        Object.keys(state.cache).length === 0
      ) {
        return;
      }

      const retryTimeoutId = setTimeout(() => {
        dispatch('processCache');
      }, state.retryDelay);
      commit('SET_RETRY_TIMEOUT_ID', retryTimeoutId);
    },

    async removeFromCache({ commit }, key) {
      commit('REMOVE_FROM_CACHE', key);
      await removeFromCache(Number(key));
    },

    /**
     * Set the connectivity state.
     * Start cache processing if the connection is restored.
     */
    setConnected({ commit, dispatch, state }, connected) {
      commit('SET_CONNECTED', connected);

      if (connected && state.retryTimeoutId === null) {
        dispatch('processCache');
      }
    },
  },
};

export default module;
