import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter);

async function isAuth(to, _from, next) {
  try {
    const user = await store.dispatch('auth/getUser');

    // Check if user is allowed to access this route based on his role
    if (
      to.meta.allowedRoles &&
      !to.meta.allowedRoles.includes(user.role.type)
    ) {
      return;
    }

    if (to.name === 'home') {
      return next({
        name: user.role.type === 'intervenant' ? 'interventions' : 'signs',
      });
    }

    return next();
  } catch (error) {
    // Redirect to login if user is not authenticated and ressource is not public
    return to.meta.allowedRoles?.includes('public')
      ? next()
      : next({ name: 'login' });
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: isAuth,
    meta: {
      allowedRoles: ['intervenant', 'manager', 'utilisateur'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/LoginView'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/RegisterView'),
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: () => import('@/views/auth/ThankYou'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPasswordView'),
  },
  {
    path: '/map',
    component: () => import('@/views/MapView'),
    beforeEnter: isAuth,
    children: [
      {
        path: '',
        name: 'map',
        component: () => import('@/components/RoadMap'),
      },
    ],
  },
  {
    path: '/signs',
    component: () => import('@/views/SignsView'),
    children: [
      {
        path: '',
        name: 'signs',
        component: () => import('@/components/Signs/Dashboard'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['manager', 'utilisateur'],
        },
      },
      {
        path: 'export',
        name: 'signs.export',
        component: () => import('@/views/SignExport'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['manager', 'utilisateur'],
        },
      },
      {
        path: 'new',
        name: 'signs.new',
        component: () => import('@/components/Signs/Item'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['manager', 'utilisateur'],
        },
      },
      {
        path: ':id',
        name: 'signs.item',
        component: () => import('@/components/Signs/Item'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['intervenant', 'manager', 'public', 'utilisateur'],
        },
      },
    ],
  },
  {
    path: '/organisation',
    name: 'Organisation',
    component: () => import('@/views/OrganisationView'),
    beforeEnter: isAuth,
  },
  {
    path: '/interventions',
    component: () => import('@/views/InterventionsView'),
    children: [
      {
        path: '',
        name: 'interventions',
        component: () => import('@/components/Interventions/Dashboard'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['intervenant', 'manager'],
        },
      },
      {
        path: 'new',
        component: () => import('@/components/Interventions/Create'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['manager'],
        },
      },
      {
        path: 'new/2',
        component: () => import('@/components/Interventions/Validate'),
        beforeEnter: isAuth,
        meta: {
          allowedRoles: ['manager'],
        },
      },
      {
        path: ':id',
        name: 'interventions.item',
        component: () => import('@/views/InterventionExport'),
        meta: {
          type: 'pdf',
        },
      },
    ],
  },
  {
    path: '/manual',
    name: 'manual',
    component: () => import('@/views/ManualView'),
    meta: {
      allowedRoles: ['intervenant', 'public', 'manager', 'utilisateur'],
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/NotFoundView'),
  },
  {
    path: '*',
    redirect: { name: 'not-found' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
