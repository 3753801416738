import api from '@/api';
import { removeEntities } from '@/utils/db';

const module = {
  namespaced: true,

  state: {
    jwt: localStorage.getItem('jwt') ?? null,
    user: null,
  },
  mutations: {
    SET_JWT(state, value) {
      if (!value) {
        state.jwt = null;
        localStorage.removeItem('jwt');
      } else {
        state.jwt = value;
        localStorage.setItem('jwt', value);
      }
    },
    SET_USER(state, value) {
      if (!value) {
        state.user = null;
        localStorage.removeItem('user');
      } else {
        state.user = value;
        localStorage.setItem('user', JSON.stringify(value));
      }
    },
  },
  actions: {
    setJwt({ commit }, value) {
      commit('SET_JWT', value);
    },
    setUser({ commit }, value) {
      commit('SET_USER', value);
    },
    async getUser({ commit, state }) {
      if (state.user) return state.user;

      if (!state.jwt) return null;

      let user = null;

      try {
        user = await api.get('/users/me?populate=role');
      } catch (error) {
        console.error(error);

        // If request failed, try to get user from local storage
        user = JSON.parse(localStorage.getItem('user') ?? null);
      }

      commit('SET_USER', user);

      return state.user;
    },
    async logout() {
      localStorage.clear();
      await removeEntities();
    }
  },
};

export default module;
