import { Network } from '@capacitor/network';

import store from '@/store';

Network.addListener('networkStatusChange', ({ connected }) => {
  store.dispatch('network/setConnected', connected);

  if (connected === false) {
    store.dispatch('message/warning', 'Connexion perdue');
  }
});

export function getNetworkStatus() {
  return Network.getStatus();
}
